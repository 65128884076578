<template>
  <div>
    <p class="text-center style-text-p">Cadastro de Relato</p>

    <div class="container">

      <div class="m-auto">
        <vuestic-widget>
          <p class="style-box">Dados do Relatante(opcional)</p>
          <div class="container">
            <div class="row">
              <div class="col">

                <div
                  class="form-group"
                  v-if="!nomeDoRelatante"
                >
                  <div class="input-group">
                    <input
                      v-model="relato.nome_do_relatante"
                      id="simple-input"
                      required
                    />
                    <label
                      class="control-label"
                      for="simple-input"
                    >Nome do Relatante
                    </label>
                  </div>
                </div>
                <div
                  class="form-group"
                  v-else
                >
                  <label class="select-control-label">Nome do Relatante</label>
                  <span class="box pr-5 pl-2">
                    {{relato.nome_do_relatante}}
                  </span>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="select-control-label">Área do Relatante</label>
                  <multiselect
                    v-model="area_do_relatante"
                    placeholder="Selecione"
                    track-by="id"
                    label="nome"
                    :options="areas"
                    selectedLabel="Selecionado"
                    select-label="Selecione uma área"
                    deselect-label="Remover área"
                  >
                    <span slot="noResult">Área não encontrado.</span>
                    <template slot="noOptions">Sem Áreas</template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <p class="style-box">Dados do Relato</p>
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="">
                  <div class="form-group">
                    <label class="select-control-label">Tipo do Relato</label>
                    <multiselect
                      v-model="tiposRelatosSelecionado"
                      placeholder="Selecione"
                      track-by="id"
                      label="nome"
                      :options="tiposRelatos"
                      selectedLabel="Selecionado"
                      select-label="Selecione uma tipo"
                      deselect-label="Remover tipo"
                    >
                      <template slot="noOptions">Sem Tipos</template>
                      <span slot="noResult">Tipo não encontrado.</span>
                    </multiselect>
                  </div>
                  <div class="form-group">
                    <label class="select-control-label">Grupo do Relato</label>
                    <multiselect
                      placeholder="Selecione"
                      v-model="grupoRelatoSelecionado"
                      track-by="id"
                      label="nome"
                      :options="grupoRelato"
                      selectedLabel="Selecionado"
                      select-label="Selecione uma tipo"
                      deselect-label="Remover tipo"
                    >
                      <template slot="noOptions">Sem Grupos</template>
                      <span slot="noResult">Grupo não encontrado.</span>
                    </multiselect>
                  </div>
                  <div class="form-group">
                    <div class="input-group">

                      <label class="select-control-label">Item do Relato *</label>
                      <multiselect
                        placeholder="Selecione"
                        track-by="id"
                        v-model="itemRelatoSelecionado"
                        label="nome"
                        :options="itemRelato"
                        selectedLabel="Selecionado"
                        select-label="Selecione uma item"
                        deselect-label="Remover item"
                      >
                        <template slot="noOptions">Sem Itens</template>
                        <span slot="noResult">Item não encontrado.</span>
                      </multiselect>
                      <span
                        class="alert-danger"
                        v-if="erros.item"
                      >{{erros['item'][0]}}</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label class="select-control-label">Área do Relatado*</label>
                      <multiselect
                        v-model="area_do_relatado"
                        placeholder="Selecione"
                        track-by="id"
                        label="nome"
                        :options="areas"
                        selectedLabel="Selecionado"
                        select-label="Selecione área"
                        deselect-label="Remover área"
                      >
                        <template slot="noOptions">Sem Áreas</template>
                        <span slot="noResult">Área não encontrado.</span>
                      </multiselect>
                      <span
                        class=" alert-danger"
                        v-if="erros.area_do_relatado"
                      >{{erros['area_do_relatado'][0]}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          type="datetime-local"
                          v-model="relato.hora"
                          id="simple-input-data"
                          required
                        >
                        <label
                          class="select-control-label"
                          for="simple-input-data"
                        >Data e Hora *</label>
                        <span
                          class=" alert-danger"
                          v-if="erros.hora"
                        >{{erros['hora'][0]}}</span>
                      </div>

                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          v-model="relato.local"
                          id="simple-input-local"
                          required
                        />
                        <label
                          class="control-label"
                          for="simple-input-local"
                        >Local*
                        </label>
                        <span
                          class="alert-danger"
                          v-if="erros.local"
                        >{{erros['local'][0]}}</span>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="form-group">
                  <div class="input-group">
                    <input
                      v-model="relato.placa"
                      id="simple-input-placa"
                      required
                    />
                    <label
                      class="control-label"
                      for="simple-input-placa"
                    >Placa:
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <input
                      v-model="relato.funcionario"
                      id="simple-input-funcionario"
                      required
                    />
                    <label
                      class="control-label"
                      for="simple-input-funcionario"
                    >Funcionário:
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <input
                      v-model="relato.funcao"
                      id="simple-input-funcao"
                      required
                    />
                    <label
                      class="control-label"
                      for="simple-input-funcao"
                    >Função:
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <div class="input-group">
                    <textarea
                      :maxlength="500"
                      type="text"
                      id="simple-textarea"
                      v-model="relato.descricao"
                      required
                    ></textarea>
                    <label
                      class="control-label"
                      for="simple-textarea"
                    >Descrição da ocorrência*</label>
                    <span
                      class=" alert-danger"
                      v-if="erros.descricao"
                    >{{erros['descricao'][0]}}</span>
                  </div>
                  <el-upload
                    class="upload-demo"
                    action=""
                    :auto-upload="false"
                    :limit="1"
                    type="file"
                    name="Imagem"
                    accept="image/*"
                    ref="upload"
                    @change="()=>null"
                    :on-change="selecionarImagem"
                    :on-remove="removerImagem"
                  >
                    <i class="foto-icon el-icon-camera"></i>

                  </el-upload>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <button
              class="btn btn-pale btn-micro mr-2"
              @click="limparCampos()"
            >Limpar Campos
            </button>
            <button
              class="btn btn-primary btn-micro"
              @click.prevent="enviarRelato()"
            >Confirmar
            </button>
          </div>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
import Compress from 'compress.js';

export default {
  name: 'relato',
  props: {
    idEmpresa: {
    },
    nomeDoRelatante: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      areas: [],
      empresa: {},
      relatos: { grupo: [], item: [] },
      tiposRelatos: [],
      tiposRelatosSelecionado: [],
      itemRelatoSelecionado: [],
      grupoRelatoSelecionado: null,
      area_do_relatante: null,
      area_do_relatado: null,
      img: null,
      relato: {
        nome_do_relatante: '',
        descricao: '',
        hora: '',
        local: '',
        placa: '',
        funcionario: '',
        funcao: '',
        empresa: null,
        item: null,
        area_do_relatante: null,
        area_do_relatado: null,
        img: null,
      },
      erros: {},
    };
  },
  created() {
    this.relato.empresa = this.idEmpresa;
    this.getEmpresa();
    this.getGrupo();
    this.getItem();
    this.relato.nome_do_relatante = this.nomeDoRelatante;
  },
  computed: {
    grupoRelato() {
      return this.tiposRelatosSelecionado
        ? this.relatos.grupo.filter(g => g.tipo === this.tiposRelatosSelecionado.id) : [];
    },
    itemRelato() {
      return this.grupoRelatoSelecionado
        ? this.relatos.item.filter(i => i.grupo === this.grupoRelatoSelecionado.id) : [];
    },
  },
  watch: {
    itemRelatoSelecionado() {
      this.relato.item = this.grupoRelatoSelecionado ? this.itemRelatoSelecionado.id : null;
    },
    area_do_relatante() {
      this.relato.area_do_relatante = this.area_do_relatante ? this.area_do_relatante.id : null;
    },
    area_do_relatado() {
      this.relato.area_do_relatado = this.area_do_relatado ? this.area_do_relatado.id : null;
    },
    tiposRelatosSelecionado() {
      this.grupoRelatoSelecionado = [];
    },
    grupoRelatoSelecionado() {
      this.itemRelatoSelecionado = [];
    },

  },
  methods: {
    getAreas(array) {
      this.$axios.get('/api/area-list/').then((res) => {
        this.areas = (res.data).filter(area => array.findIndex(el => el === area.id) !== -1);
      });
    },
    removerImagem() {
      this.img = null;
      this.$refs.upload.clearFiles();
    },
    prepararForm() {
      const { relato } = this;
      const fb = new FormData();
      fb.append('nome_do_relatante', relato.nome_do_relatante);
      fb.append('descricao', relato.descricao);
      fb.append('area_do_relatado', relato.area_do_relatado);
      fb.append('hora', relato.hora);
      fb.append('local', relato.local);
      fb.append('placa', relato.placa);
      fb.append('funcionario', relato.funcionario);
      fb.append('funcao', relato.funcao);
      fb.append('empresa', relato.empresa);
      fb.append('item', relato.item);
      fb.append('area_do_relatante', relato.area_do_relatante);
      if (this.img) fb.append('imagem', this.img);
      return fb;
    },
    getEmpresa() {
      this.$axios.get(`/api/empresa-list/${this.relato.empresa}/`).then((res) => {
        this.empresa = res.data;
        this.getTipo(res.data.modelo);
        this.getAreas(res.data.areas);
      });
    },
    getTipo(id) {
      this.$axios.get('api/tipo-de-relato-list/').then((res) => {
        this.tiposRelatos = res.data.filter(t => t.modelo === id);
      });
    },
    getGrupo() {
      this.$axios.get('/api/grupo-list/').then((res) => {
        this.relatos.grupo = res.data;
      });
    },
    getItem() {
      this.$axios.get('/api/item-list/').then((res) => {
        this.relatos.item = res.data;
      });
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    srcToFile(src, fileName, mimeType) {
      return (fetch(src)
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], fileName, { type: mimeType }))
      );
    },
    selecionarImagem(event) {
      const compress = new Compress();
      compress.compress([event.raw], {
        size: 1,
        quality: 0.75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true,
      }).then((data) => {
        this.srcToFile(
          data[0].prefix + data[0].data,
          data[0].alt,
          data[0].ext,
        ).then((file) => {
          this.img = file;
        });
      });
    },
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      // eslint-disable-next-line no-plusplus
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    enviarRelato() {
      this.$axios.post('/api/relato/', this.prepararForm()).then(() => {
        this.limparCampos();
        this.message('success', 'Relato Enviado!');
      }).catch((erro) => {
        this.message('error', 'Erro ao tentar relatar');
        this.erros = erro.response.data;
      });
    },
    limparCampos() {
      this.removerImagem();
      this.erros = {};
      this.area_do_relatante = null;
      this.area_do_relatado = null;
      this.tipo_de_atos_selecionado = null;
      this.tiposRelatosSelecionado = null;
      this.grupoRelatoSelecionado = null;
      this.itemRelatoSelecionado = null;
      this.img = null;
      this.relato = {
        ato_seguro: null,
        nome_do_relatante: this.nomeDoRelatante,
        descricao: '',
        hora: '',
        local: '',
        placa: '',
        funcionario: '',
        funcao: '',
        empresa: this.idEmpresa,
        item: null,
        img: null,
        area_do_relatante: null,
        area_do_relatado: null,
      };
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 550px) {
	.row .col {
		flex-basis: 100% !important;
	}
}
.box {
	padding: 0.125rem 0.125rem 0.0625rem;
	border: 1px solid #ccc;
	border-radius: 5px;
	width: 100%;
	height: 2.6rem;
}
h2 {
	margin-top: 1rem;
}
.vuestic-widget {
	margin-top: 3rem;
}

.el-upload__input {
	display: none !important;
}
.foto-icon {
	position: absolute;
	top: 26px;
	right: 9px;
	font-size: 2rem;
}

.el-upload-list__item-name {
	color: #606266 !important;
}
.el-upload-list {
	position: absolute;
	top: 62px;
	right: -4px;
}
</style>
